import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import { Layout } from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"
import * as Styles from "./index.module.scss"

/* ==================================================
  画像の読み込み
================================================== */
import tamec from "../images/tamec_new_logo.svg"
import illust01 from "../images/top/mv_illust01.svg"
import illust02 from "../images/top/mv_illust02.svg"
import illust03 from "../images/top/mv_illust03.svg"
import illust04 from "../images/top/mv_illust04.svg"
import illust05 from "../images/top/mv_illust05.svg"

import tamkun from "../images/top/p-concept--tamkun.png"

import work01 from "../images/top/p-work--01.jpg"
import work02 from "../images/top/p-work--02.jpg"
import work03 from "../images/top/p-work--03.jpg"
import work04 from "../images/top/p-work--04.jpg"

// request（スポット依頼...）
import request01 from "../images/service/small_start.jpg"
import request02 from "../images/service/shopify_tam.jpg"

import jobCase01 from "../images/top/p-job-case--01.jpg"
import jobCase02 from "../images/top/p-job-case--02.jpg"

import serviceIllustTools from "../images/top/p-service--illustTools.png"
import serviceIllust01 from "../images/top/p-service--illust01.png"
import serviceIllust02 from "../images/top/p-service--illust02.png"
import serviceIllust03 from "../images/top/p-service--illust03.png"
import serviceIllust04 from "../images/top/p-service--illust04.png"

//各ECカートのロゴ
import ecbeing from "../images/top/p-logo--ecbeing.png"
import shopify from "../images/top/p-logo--shopify.png"
import futureshop from "../images/top/p-logo--futureshop.png"
import ebisumart from "../images/top/p-logo--ebisumart.png"
import makeshop from "../images/top/p-logo--makeshop.png"
import melcart from "../images/top/p-logo--melcart.png"
import ecorigin from "../images/top/p-logo--ecorigin.png"
import logica from "../images/top/p-logo--logica.png"
import eccube from "../images/top/p-logo--eccube.png"
import w2commerceValue5 from "../images/top/p-logo--w2commerceValue5.png"
import ecforce from "../images/top/p-logo--ecforce.png"
import company from "../images/top/p-logo--company.png"
import aishipr from "../images/top/p-logo--aishipr.png"
import repeatplus from "../images/top/p-logo--repeatplus.png"
import subscstore from "../images/top/p-logo--subscstore.png"
import makeshop02 from "../images/top/p-logo--makeshop.svg"
import shopline from "../images/top/p-logo--shopline.png"
import gmoCloudEc from "../images/top/p-logo--gmocloudec.png"
import w2UnifiedCommerce from "../images/top/p-logo--w2unifiedcommerce.png"
import w2Repeat from "../images/top/p-logo--w2repeat.png"

// アイコン
import facebook from "../images/top/facebook_icon.svg"
import twitter from "../images/top/twitter_icon.svg"

/* ==================================================
  コンポーネントの読み込み
================================================= */
import { Form } from "@/components/form"
import scrollTo from "gatsby-plugin-smoothscroll"
import TargetFadeIn from "../components/targetFadeIn"
import TargetFadeInDelay from "../components/targetFadeInDelay"
import TargetBounce from "../components/targetBounce"

const TopPage: React.VFC = () => {
  return (
    <>
      <SEO title="ECサイトのカート選定から構築・プロモーションまで | TAM" />
      <Layout>
        <div className={Styles.main} id="top">
          <section className={Styles.top}>
            <div className={Styles.topContainer}>
              <div className={Styles.topTextWrap}>
                <div className={Styles.topTitleWrap}>
                  <h1 className={Styles.topTitle}>
                    <img src={tamec} alt="TAM EC" width="627" height="165" />
                  </h1>
                  <p className={Styles.topText}>
                    カート選定から開発、運用、
                    <br className={Styles.spOnly} />
                    プロモーションまで、
                    <br className={Styles.pcOnly} />
                    TAMだからできること。
                    <br className={Styles.spOnly} />
                    <span> あきない</span>体験価値を。
                  </p>
                </div>
                <div className={Styles.buttonWrap}>
                  <a
                    onClick={() => scrollTo("#contact")}
                    className={Styles.button}
                  >
                    お問い合わせ
                  </a>
                </div>
              </div>
              <div className={Styles.topIllust01}>
                <img
                  src={illust01}
                  alt="MV　レポートイラスト"
                  width="305"
                  height="165"
                />
              </div>
              <div className={Styles.topIllust02}>
                <img
                  src={illust02}
                  alt="MV　カラー棒グラフ"
                  width="111"
                  height="150"
                />
              </div>
              <div className={Styles.topIllust03}>
                <img src={illust03} alt="MV　STORE" width="245" height="272" />
              </div>
              <div className={Styles.topIllust04}>
                <img
                  src={illust04}
                  alt="MV　広告、レター、メガホン"
                  width="230"
                  height="240"
                />
              </div>
              <div className={Styles.topIllust05}>
                <img
                  src={illust05}
                  alt="MV　カートとtamくん"
                  width="280"
                  height="320"
                />
              </div>
            </div>
          </section>

          {/* <section className={Styles.support}>
            {data ? <BlogPage data={data} /> : null}
          </section> */}

          {/* ここから新規作成 コンセプト*/}

          <section className={Styles.concept}>
            <div className={Styles.conceptTextarea}>
              <div className={Styles.conceptHeadWrap}>
                <TargetFadeInDelay>
                  <p className={Styles.sectionCategory}>コンセプト</p>
                </TargetFadeInDelay>
                <TargetFadeInDelay>
                  <h2 className={Styles.sectionHead}>
                    一緒に悩んで、
                    <br />
                    創って、
                    <br />
                    成果に喜びます。
                  </h2>
                </TargetFadeInDelay>
              </div>
              <TargetFadeInDelay>
                <p className={Styles.conceptText}>
                  TAM ECチームは、28年続くデジタルエージェンシーのTAMにある
                  EC専門のプロ集団です。
                  <br />
                  TAMグループの一員として、製品・サービスの新しい価値を共創し、
                  <br />
                  制作・開発のモノづくりから、コンバージョン・現場運用まで担います。
                  <br />
                  各メンバーがより専門性を追求し、よりフレキシブルに連携して、
                  <br />
                  お客様の課題解決の支援をいたします。
                </p>
              </TargetFadeInDelay>
              {/* <TargetFadeIn>
                <div className={Styles.buttonWrap}>
                  <a href="https://www.tam-tam.co.jp/service/ec/" target="_blank" className={Styles.button}>
                    TAM ECチームのコンセプトをもっと知る
                  </a>
                </div>
              </TargetFadeIn> */}
            </div>
            <TargetBounce>
              <div className={Styles.conceptIllust}>
                <img data-src={tamkun} className="lazyload" alt="Tamくん" />
              </div>
            </TargetBounce>
          </section>

          {/* 実績紹介 */}

          <section className={Styles.work} id="work">
            <div className={Styles.container}>
              <div className={Styles.workTop}>
                <TargetFadeIn>
                  <p className={Styles.sectionCategory}>実績紹介</p>
                </TargetFadeIn>
                <div>
                  <TargetFadeIn>
                    <h2 className={Styles.sectionHead}>
                      日本中のECが成功してほしいから。
                    </h2>
                  </TargetFadeIn>
                  <TargetFadeIn>
                    <p className={Styles.workText}>
                      TAM
                      ECチームでは大手企業様を中心に、多くの企業様のECサイトと向き合ってきました。
                      <br />
                      新規カートの立ち上げはもちろん、既存カートの改善も豊富な経験をもとに⾒た目だけではなく、
                      <br />
                      成果を上げることを⽬的としたECサイトを構築します。
                    </p>
                  </TargetFadeIn>
                </div>
              </div>
              <div className={Styles.worksWrap}>
                <div className={Styles.workListTop}>
                  <TargetFadeIn>
                    <p className={Styles.workNumber}>
                      0<span>1</span>
                    </p>
                    <a
                      href="https://www.bellemaison.jp/"
                      target="_blank"
                      rel="noreferrer"
                      className={Styles.workLink}
                    >
                      <figure>
                        <img
                          data-src={work01}
                          className="lazyload"
                          alt="実績紹介01"
                          width="570"
                          height="510"
                        />
                      </figure>
                    </a>
                  </TargetFadeIn>
                </div>
                <div className={Styles.workTextarea}>
                  <div>
                    <TargetFadeIn>
                      <h3 className={Styles.workSubHead}>
                        大規模ECサイトの
                        <br className={Styles.lgdisplay} />
                        {/* <br className={Styles.displayNone} /> */}
                        運用全般をサポート
                      </h3>
                    </TargetFadeIn>
                    <TargetFadeIn>
                      <p className={Styles.workText}>
                        サイトデザインの改修に、特集ページ・メールマガジン・バナー
                        といったコンテンツの運用や、レスポンシブやリキッドコンテンツテンプレートの導入、そして各種最適化の改善施策や、モバイルアプリの開発から、クライアント先へ常駐しての更新管理まで。パートナープロダクションとして、大規模ECサイトの運用を長期にわたって実施しています。
                      </p>
                      <a
                        href="https://www.tam-tam.co.jp/work/bellemaison/"
                        target="_blank"
                        rel="noreferrer"
                        className={Styles.workMore}
                      >
                        詳細を見る
                      </a>
                    </TargetFadeIn>
                    <TargetFadeIn>
                      <div>
                        <a
                          href="https://www.bellemaison.jp/"
                          target="_blank"
                          rel="noreferrer"
                          className={Styles.workName}
                        >
                          株式会社 千趣会様 ベルメゾンネット
                        </a>
                        <p className={Styles.workCategory}>総合通販</p>
                      </div>
                    </TargetFadeIn>
                  </div>
                </div>
              </div>
              <div className={Styles.worksWrap}>
                <div className={Styles.workListTop}>
                  <TargetFadeIn>
                    <p className={Styles.workNumber}>
                      0<span>2</span>
                    </p>
                    <a href="" target="_blank" className={Styles.workLink}>
                      <figure>
                        <img
                          data-src={work02}
                          className="lazyload"
                          alt="実績紹介02"
                          width="570"
                          height="510"
                        />
                      </figure>
                    </a>
                  </TargetFadeIn>
                </div>
                <div className={Styles.workTextarea}>
                  <div>
                    <TargetFadeIn>
                      <h3 className={Styles.workSubHead}>
                        ECで提供したい
                        <br className={Styles.lgdisplay} />
                        {/* <br className={Styles.displayNone} /> */}
                        体験をカート選定に
                        <br className={Styles.lgdisplay} />
                        落とし込むコンサル
                      </h3>
                    </TargetFadeIn>
                    <TargetFadeIn>
                      <p className={Styles.workText}>
                        ECサイトの構築には、カートシステムの選定が重要になります。クラウド型かパッケージ型か、どれくらいカスタマイズするか
                        ー
                        選定するアプローチにより、ECサイトが提供できる顧客体験や、その開発コストが大きく変わるからです。
                        メディカ出版様のECで実現したいことの本質を抽出し、それにはどんなカートが求められるのか、EC構築の知見をフル活用して
                        コンサルティング業務をお手伝いしました。
                      </p>
                      <a
                        href="https://www.tam-tam.co.jp/work/medicasyuppan/"
                        target="_blank"
                        rel="noreferrer"
                        className={Styles.workMore}
                      >
                        詳細を見る
                      </a>
                    </TargetFadeIn>
                    <TargetFadeIn>
                      <div>
                        <p className={Styles.workName + " " + Styles.isNormal}>
                          メディカ出版様
                        </p>
                        <p className={Styles.workCategory}>出版</p>
                      </div>
                    </TargetFadeIn>
                  </div>
                </div>
              </div>
              {/* <div className={Styles.workListsWrap}>
                <TargetFadeIn>
                  <div className={Styles.workList}>
                    <p className={Styles.workNumber}>
                      0<span>3</span>
                    </p>
                    <div className={Styles.workImageBlock}>
                      <a
                        href="https://www.daimaru-matsuzakaya.jp/"
                        target="_blank"
                        rel="noreferrer"
                        className={Styles.workLink}
                      >
                        <figure>
                          <img
                            data-src={work03}
                            className="lazyload"
                            alt="実績紹介03"
                            width="480"
                            height="430"
                          />
                        </figure>
                      </a>
                      <div>
                        <a
                          href="https://www.daimaru-matsuzakaya.jp/"
                          target="_blank"
                          rel="noreferrer"
                          className={Styles.workName}
                        >
                          大丸松坂屋オンラインショップ
                        </a>
                        <p className={Styles.workCategory}>総合通販</p>
                      </div>
                    </div>
                  </div>
                </TargetFadeIn>
                <TargetFadeIn>
                  <div className={Styles.workList}>
                    <p className={Styles.workNumber}>
                      0<span>4</span>
                    </p>
                    <div className={Styles.workImageBlock}>
                      <a
                        href="https://tiger-netshop.jp/shop/default.aspx"
                        target="_blank"
                        rel="noreferrer"
                        className={Styles.workLink}
                      >
                        <figure>
                          <img
                            data-src={work04}
                            className="lazyload"
                            alt="実績紹介04"
                            width="480"
                            height="430"
                          />
                        </figure>
                      </a>
                      <div>
                        <a
                          href="https://tiger-netshop.jp/shop/default.aspx"
                          target="_blank"
                          rel="noreferrer"
                          className={Styles.workName}
                        >
                          タイガー魔法瓶様 オンラインショップ
                        </a>
                        <p className={Styles.workCategory}>家電</p>
                      </div>
                    </div>
                  </div>
                </TargetFadeIn>
              </div> */}
              {/* <TargetFadeIn>
                <div className={Styles.buttonWrap}>
                  <a
                    href="https://www.tam-tam.co.jp/work/"
                    target="_blank"
                    className={Styles.button}
                  >
                    TAMの実績紹介をもっと知る
                  </a>
                </div>
              </TargetFadeIn> */}
            </div>
          </section>

          {/* サービス */}

          <section className={Styles.service}>
            <div className={Styles.container}>
              <div className={Styles.serviceHeadWrap}>
                <TargetFadeIn>
                  <p className={Styles.sectionCategory}>サービス</p>
                </TargetFadeIn>
                <TargetFadeIn>
                  <h3 className={Styles.serviceSubHead}>
                    国内外10種類以上のカート対応
                  </h3>
                  <p className={Styles.serviceText}>
                    高いフロントエンド技術で、さまざまなカートCMSに対応可能です。
                  </p>
                </TargetFadeIn>
              </div>
            </div>
            <div className={Styles.cart}>
              <div className={Styles.container}>
                <ul className={Styles.cartLists}>
                  <li className={Styles.cartLogoBlock}>
                    <img
                      data-src={ecbeing}
                      className="lazyload"
                      alt="ecbeingロゴ"
                    />
                  </li>
                  <li className={Styles.cartLogoBlock}>
                    <img
                      data-src={shopify}
                      className="lazyload"
                      alt="shopifyロゴ"
                    />
                  </li>
                  <li className={Styles.cartLogoBlock}>
                    <img
                      data-src={ecforce}
                      className="lazyload"
                      alt="ecforceロゴ"
                    />
                  </li>
                  <li className={Styles.cartLogoBlock}>
                    <img
                      data-src={eccube}
                      className="lazyload"
                      alt="eccubeロゴ"
                    />
                  </li>
                  <li className={Styles.cartLogoBlock}>
                    <img
                      data-src={shopline}
                      className="lazyload"
                      alt="shoplineロゴ"
                    />
                  </li>
                  <li className={Styles.cartLogoBlock}>
                    <img
                      data-src={makeshop}
                      className="lazyload"
                      alt="makeshopロゴ"
                    />
                  </li>
                  <li className={Styles.cartLogoBlock}>
                    <img
                      data-src={futureshop}
                      className="lazyload"
                      alt="futureshopロゴ"
                    />
                  </li>
                  <li className={Styles.cartLogoBlock}>
                    <img
                      data-src={gmoCloudEc}
                      className="lazyload"
                      alt="GMO クラウドECロゴ"
                    />
                  </li>
                  <li className={Styles.cartLogoBlock}>
                    <img
                      data-src={ebisumart}
                      className="lazyload"
                      alt="ebisumartロゴ"
                    />
                  </li>
                  <li className={Styles.cartLogoBlock}>
                    <img
                      data-src={aishipr}
                      className="lazyload"
                      alt="aishiprロゴ"
                    />
                  </li>
                  <li className={Styles.cartLogoBlock}>
                    <img
                      data-src={w2UnifiedCommerce}
                      className="lazyload"
                      alt="W2 Unified Commerce ロゴ"
                    />
                  </li>
                  <li className={Styles.cartLogoBlock}>
                    <img
                      data-src={w2Repeat}
                      className="lazyload"
                      alt="W2 Repeat ロゴ"
                    />
                  </li>
                  <li className={Styles.cartLogoBlock}>
                    <img
                      data-src={subscstore}
                      className="lazyload"
                      alt="subscstoreロゴ"
                    />
                  </li>
                  <li className={Styles.cartLogoBlock}>
                    <img
                      data-src={melcart}
                      className="lazyload"
                      alt="melcartロゴ"
                    />
                  </li>
                </ul>
                <div className={Styles.cartBgIllust}>
                  <img
                    data-src={serviceIllustTools}
                    className="lazyload"
                    alt="背景の工具のイラスト"
                  />
                </div>
              </div>
            </div>
            <div className={Styles.container}>
              <TargetFadeIn>
                <h3 className={Styles.serviceSubHead}>
                  課題抽出から運用まで、ワンストップでECをサポート
                </h3>
              </TargetFadeIn>
              <ul className={Styles.serviceListsWrap}>
                <li className={Styles.serviceList}>
                  <div className={Styles.serviceNumberWrap}>
                    <TargetFadeIn>
                      <p className={Styles.serviceNumber}>
                        0<span>1</span>
                      </p>
                      <figure className={Styles.serviceNumberIllust}>
                        <img
                          data-src={serviceIllust01}
                          className="lazyload"
                          alt="課題抽出・カート選定"
                        />
                      </figure>
                    </TargetFadeIn>
                  </div>
                  <div className={Styles.serviceListText}>
                    <TargetFadeIn>
                      <h4 className={Styles.serviceListHead}>
                        課題抽出・カート選定
                      </h4>
                    </TargetFadeIn>
                    <TargetFadeIn>
                      <p className={Styles.serviceText}>
                        制作実績をもとに独自に課題を抽出し、課題を解決するカート選定を行います。TAMでは英語圏のスタッフやECプラットフォームの開発者との代理コミュニケーションも可能で、シームレスにサイト構築が行えます。
                      </p>
                    </TargetFadeIn>
                  </div>
                </li>
                <li className={Styles.serviceList}>
                  <div className={Styles.serviceNumberWrap}>
                    <TargetFadeIn>
                      <p className={Styles.serviceNumber}>
                        0<span>2</span>
                      </p>
                      <figure className={Styles.serviceNumberIllust}>
                        <img
                          data-src={serviceIllust02}
                          className="lazyload"
                          alt="戦略・プランニング"
                        />
                      </figure>
                    </TargetFadeIn>
                  </div>
                  <div className={Styles.serviceListText}>
                    <TargetFadeIn>
                      <h4 className={Styles.serviceListHead}>
                        戦略・プランニング
                      </h4>
                    </TargetFadeIn>
                    <TargetFadeIn>
                      <p className={Styles.serviceText}>
                        ビジネスゴールに向け、TAM独自のフレームワークをもとに「開発」から「コンバージョン」までカート運用をサポートします。
                      </p>
                    </TargetFadeIn>
                  </div>
                </li>
                <li className={Styles.serviceList}>
                  <div className={Styles.serviceNumberWrap}>
                    <TargetFadeIn>
                      <p className={Styles.serviceNumber}>
                        0<span>3</span>
                      </p>
                      <figure className={Styles.serviceNumberIllust}>
                        <img
                          data-src={serviceIllust03}
                          className="lazyload"
                          alt="サイト構築"
                        />
                      </figure>
                    </TargetFadeIn>
                  </div>
                  <div className={Styles.serviceListText}>
                    <TargetFadeIn>
                      <h4 className={Styles.serviceListHead}>サイト構築</h4>
                    </TargetFadeIn>
                    <TargetFadeIn>
                      <p className={Styles.serviceText}>
                        ECの構築・運用を行う中で、課題と改善を重ねてECに特化した、UIUX知識・フロントエンド技術を強化し、ナレッジを磨いてきました。TAMではより洗練されたサイト構築が可能です。
                      </p>
                    </TargetFadeIn>
                  </div>
                </li>
                <li className={Styles.serviceList}>
                  <div className={Styles.serviceNumberWrap}>
                    <TargetFadeIn>
                      <p className={Styles.serviceNumber}>
                        0<span>4</span>
                      </p>
                      <figure className={Styles.serviceNumberIllust}>
                        <img
                          data-src={serviceIllust04}
                          className="lazyload"
                          alt="運用・コンテンツ制作"
                        />
                      </figure>
                    </TargetFadeIn>
                  </div>
                  <div className={Styles.serviceListText}>
                    <TargetFadeIn>
                      <h4 className={Styles.serviceListHead}>
                        運用・コンテンツ制作
                      </h4>
                    </TargetFadeIn>
                    <TargetFadeIn>
                      <p className={Styles.serviceText}>
                        ECサイト運用でPDCAサイクルの実行を行い、サイト改善に継続的に取り組んでいきます。
                      </p>
                    </TargetFadeIn>
                  </div>
                </li>
              </ul>
              <TargetFadeIn>
                <div className={Styles.buttonWrap}>
                  <Link to="/service/" className={Styles.button}>
                    TAM ECチームのサービスをもっと知る
                  </Link>
                </div>
              </TargetFadeIn>
            </div>
          </section>

          <section className={Styles.request}>
            <div className={Styles.container}>
              <TargetFadeIn>
                <h2 className={Styles.requestTitle}>
                  スポットでのご依頼もご相談ください。
                </h2>
                <p className={Styles.requestText}>
                  ECサイト構築のみ、各種ランディングページやバナーなどの制作運用のみ、
                  <br className={Styles.pcOnly} />
                  といったスポットでのご依頼もお気軽にご相談ください。
                </p>
              </TargetFadeIn>
              <ul className={Styles.requestList}>
                <li className={Styles.requestContent}>
                  <TargetFadeIn>
                    <Link to="/service/package/">
                      <h3 className={Styles.requestSubheading}>
                        ECのスモールスタートを応援
                      </h3>
                      <p className={Styles.requestText}>
                        まずはECサイトを作成したい。という希望にお答えします！
                        ビジネスの成長に合わせたECサイト構築が可能です。
                      </p>
                      <div className={Styles.requestImg}>
                        <img
                          data-src={request01}
                          className="lazyload"
                          alt="SMALL START 応援プラン"
                          width="380"
                          height="254"
                        />
                      </div>
                    </Link>
                  </TargetFadeIn>
                </li>
                <li className={Styles.requestContent}>
                  <TargetFadeIn>
                    <a
                      href="https://cross-border.oh-tam.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <h3 className={Styles.requestSubheading}>
                        英語版越境EC×shopify
                      </h3>
                      <p className={Styles.requestText}>
                        越境ECなら世界NO.1シェアのshopifyがオススメ！TAMでは、英語と⽇本語に対応した越境ECサイトの
                        構築が可能です。
                      </p>
                      <div className={Styles.requestImg}>
                        <img
                          data-src={request02}
                          className="lazyload"
                          alt="越境ECなら Shopify×TAM"
                          width="380"
                          height="254"
                        />
                      </div>
                    </a>
                  </TargetFadeIn>
                </li>
              </ul>
            </div>
          </section>

          {/* メンバー */}

          <section className={Styles.member}>
            <div className={Styles.container}>
              <div className={Styles.sectionHeadWrap}>
                <TargetFadeIn>
                  <p className={Styles.sectionCategory}>メンバー</p>
                </TargetFadeIn>
                <TargetFadeIn>
                  <h2 className={Styles.sectionHead}>
                    TAM ECチームのクリエイティブ、
                    <br className={Styles.lgdisplay} />
                    テクノロジーを支えるPEOPLE。
                  </h2>
                </TargetFadeIn>
              </div>
              <div className={Styles.memberLeader}>
                <div className={Styles.memberLeaderImg}>
                  <TargetFadeIn>
                    <StaticImage
                      src="../images/top/p-person--hikino.png"
                      alt="引野 文也の写真"
                      loading="lazy"
                      quality={80}
                    />
                  </TargetFadeIn>
                </div>
                <div className={Styles.memberLeaderInfo}>
                  <TargetFadeIn>
                    <p className={Styles.memberLeaderName}>引野 ⽂也</p>
                    <p className={Styles.memberLeaderJob}>
                      TAM ECチーム責任者&nbsp;
                      <br className={Styles.spOnly} />/ ECプランナー
                    </p>
                    <p className={Styles.memberLeaderText}>
                      学⽣時代、アルバイト先の飲⾷店でECサイトの⽴ち上げを経験。
                      そこでECの可能性や楽しさを感じ、新卒でTAMにジョイン。
                      現在はECチームの責任者として、戦略⽴案から戦略実⾏までEC領域を広く担う。
                    </p>
                    <p className={Styles.memberLeaderText}>
                      ・50ブランド以上のECサイト⽀援に従事
                      <br />
                      ・グループ最年少で役員就任
                      <br />
                      ・関⻄EC交流会 主幹
                    </p>
                    <ul className={Styles.iconList}>
                      <li className={Styles.iconItem}>
                        <a
                          href="https://www.facebook.com/hikino.f"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img
                            data-src={facebook}
                            className="lazyload"
                            alt="facebookアイコン"
                          />
                        </a>
                      </li>
                    </ul>
                  </TargetFadeIn>
                </div>
              </div>
              <div className={Styles.memberListsWrap}>
                <ul className={Styles.memberLists}>
                  <li className={Styles.memberList}>
                    <TargetFadeIn>
                      <StaticImage
                        src="../images/top/p-person--yonemoto.png"
                        alt=""
                        loading="lazy"
                        quality={80}
                      />
                    </TargetFadeIn>
                    <TargetFadeIn>
                      <p className={Styles.memberName}>米本 和生</p>
                      <p className={Styles.memberJob}>
                        取締役/テクニカルディレクター
                      </p>
                    </TargetFadeIn>
                    {/* <TargetFadeIn>
                      <p className={Styles.memberText}>
                        機能性を追求した構築だけではなく、ユーザーへのインパクト、競合サイトとの差別化など最新技術を取り入れて効果アップを実現させています。
                      </p>
                    </TargetFadeIn> */}
                  </li>
                  <li className={Styles.memberList}>
                    <TargetFadeIn>
                      <StaticImage
                        src="../images/top/p-person--kami.png"
                        alt=""
                        loading="lazy"
                        quality={80}
                      />
                    </TargetFadeIn>
                    <TargetFadeIn>
                      <p className={Styles.memberName}>加美 奈津子</p>
                      <p className={Styles.memberJob}>デザイナー</p>
                    </TargetFadeIn>
                  </li>
                  <li className={Styles.memberList}>
                    <TargetFadeIn>
                      <StaticImage
                        src="../images/top/p-person--kitagawa.png"
                        alt=""
                        loading="lazy"
                        quality={80}
                      />
                    </TargetFadeIn>
                    <TargetFadeIn>
                      <p className={Styles.memberName}>北川 優輔</p>
                      <p className={Styles.memberJob}>エンジニア</p>
                    </TargetFadeIn>
                  </li>
                </ul>
              </div>
              <TargetFadeIn>
                <div className={Styles.buttonWrap}>
                  <a
                    href="https://www.tam-tam.co.jp/about/staff/"
                    target="_blank"
                    rel="noreferrer"
                    className={Styles.button}
                  >
                    TAMの人をもっと知る
                  </a>
                </div>
                <div className={Styles.buttonWrap + " " + Styles.isWhite}>
                  <a
                    href="https://www.tam-tam.co.jp/"
                    target="_blank"
                    rel="noreferrer"
                    className={Styles.button}
                  >
                    TAMサイトへ
                  </a>
                </div>
              </TargetFadeIn>
            </div>
            <div className={Styles.jobCase}>
              <ul className={Styles.jobCaseList}>
                <li className={Styles.jobCaseItem}>
                  <TargetFadeIn>
                    <h3 className={Styles.jobCaseTitle}>
                      Shopify Partner Boot Camp
                      <br className={Styles.spOnly} />
                      に講師として登壇
                    </h3>
                    <div
                      className={`${Styles.jobCaseImg} ${Styles.jobCaseImgSm}`}
                    >
                      <img
                        data-src={jobCase01}
                        className="lazyload"
                        alt="Shopify Partner Boot Camp"
                      />
                    </div>
                  </TargetFadeIn>
                </li>
                <li className={Styles.jobCaseItem}>
                  <TargetFadeIn>
                    <h3 className={Styles.jobCaseTitle}>
                      関西EC交流会を定期的に実施
                    </h3>
                    <div className={Styles.jobCaseImg}>
                      <img
                        data-src={jobCase02}
                        className="lazyload"
                        alt="関西EC交流会"
                      />
                    </div>
                  </TargetFadeIn>
                </li>
              </ul>
            </div>
          </section>

          {/* 資料ダウンロード */}

          {/* <section>
            <div className={Styles.container}>
              <TargetFadeIn>
                <h2 className={Styles.downloadHead}>資料ダウンロード</h2>
              </TargetFadeIn>
              <TargetFadeIn>
                <ul className={Styles.downloadLists}>
                  {data.allMicrocmsBlog.edges.map(({ node }) => (
                    <React.Fragment key={node.id}>
                      <li className={Styles.downloadList}>
                        <Link to={`/blog/${node.title}`} key={node.id}>
                          <figure>
                            <img
                              data-src={node.img.url}
                              className="lazyload"
                              alt="ダウンロード資料"
                            />
                          </figure>
                          <p className={Styles.downloadText}>{node.title}</p>
                          <p className={Styles.downloadText}>
                            {node.publishedAt}
                          </p>
                        </Link>
                      </li>
                    </React.Fragment>
                  ))}
                </ul>
              </TargetFadeIn>
            </div>
          </section> */}
          <Form />
        </div>
      </Layout>
    </>
  )
}

export default TopPage
